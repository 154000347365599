import slider from "./slider";
export default function setup(context) {
  var containers = context === null || context === void 0 ? void 0 : context.getElementsByClassName("slideshow");
  if (!(containers !== null && containers !== void 0 && containers.length)) return;
  for (var i = 0, len = containers.length; i < len; ++i) {
    slideshow(containers[i]);
  }
}
export function slideshow(container) {
  var _slider = slider(container);
  if (!_slider) return;
  slideshowControls(container.getElementsByClassName("slideshow__arrow"), _slider);
  return _slider;
}
function slideshowControls(buttons, _slider) {
  if (buttons.length !== 2) return;
  buttons[0].addEventListener("click", _slider.slideToPrevious);
  buttons[1].addEventListener("click", _slider.slideToNext);
}